import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import addEditPtcarPlatform from './Services/addEditPtcarPlatform'
import deletePlatform from './Services/deletePtcarPlatform'
import getPtcarPlatforms from './Services/getPtcarPlatforms'
import getPtCars from './Services/getPtCars'

import getPlatforms from '../Platforms/Services/getPlatforms'
import getAllLocations from 'modules/Performances/Services/getAllLocations'

import { TranslationContext } from 'context/translation'
import getPlatformById from './Services/getPlatformById'
import updatePlatform from './Services/updatePlatform'
import addPlatform from './Services/addPlatform'

const defaultProps = {}

export const PtcarPlatformsContext = React.createContext(defaultProps)

const StoreProvider = ({ children }) => {
    const [data, setData] = useState([])
    const [editDrawer, setEditDrawer] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [newDrawer, setNewDrawer] = useState(false)
    const [editData, setEditData] = useState('')
    const [addEditError, setAddEditError] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteRowData, setDeleteRowData] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [platformFilterValue, setPlatformFilterValue] = useState('')
    const [platforms, setPlatforms] = useState([])
    const [ptcars, setPtcars] = useState([])
    const [platformsFilterd, setPlatformsFilterd] = useState([])
    const [ptCarsLists, setPtCarsLists] = useState([])

    const { activeLanguage } = useContext(TranslationContext)

    const STATE = {
        data,
        setData,
        editDrawer,
        setEditDrawer,
        newDrawer,
        setNewDrawer,
        openDrawer,
        setOpenDrawer,
        editData,
        setEditData,
        addEditError,
        setAddEditError,
        filteredData,
        setFilteredData,
        openDeleteDialog,
        setOpenDeleteDialog,
        deleteRowData,
        setDeleteRowData,
        loadingData,
        setLoadingData,
        platformFilterValue,
        setPlatformFilterValue,
        platforms,
        setPlatforms,
        ptcars,
        setPtcars,
        platformsFilterd,
        setPlatformsFilterd,
        ptCarsLists,
    }

    const METHODS = {
        loadPtcarPlatforms: async () => {
            setLoadingData(true)
            const ptcarplatforms = await SERVICES.getPtcarPlatforms()
            const platformResponse = await getPlatforms()

            const items = platformResponse.map(platform => ({
                ...platform,
                text: platform.platformName,
            }))

            const ptcarsResponse = await getAllLocations()

            const ptcarList = ptcarsResponse.map(({ uic, commercialName }) => ({
                code: uic,
                label: activeLanguage?.code === 'fr' ? commercialName[0]?.translation : commercialName[1]?.translation,
            }))
            setData(ptcarplatforms)
            setPlatforms(items)
            setPlatformsFilterd(items)
            setPtcars(ptcarList)
            setLoadingData(false)
        },
        loadPlatformData: async () => {
            const response = await SERVICES.getPlatformById(editData.platformId)
            return response
        },
        onRowDeleteClick: (e, rowData) => {
            setOpenDeleteDialog(true)
            setDeleteRowData(rowData)

            e.stopPropagation()
        },
        handleAddPlatform: async platformToAdd => {
            let response = await SERVICES.addPlatform(platformToAdd)
            if (response.status === 200) {
                setAddEditError('')
                setOpenDrawer(false)
                setEditDrawer(false)
                METHODS.loadPtcarPlatforms()
            } else {
                setAddEditError(response.data.Message)
            }
        },
        handleUpdatePlatform: async platform => {
            let response = await SERVICES.updatePlatformById(platform)
            if (response.status === 200) {
                setAddEditError('')
                setOpenDrawer(false)
                setEditDrawer(false)
                METHODS.loadPtcarPlatforms()
            } else {
                setAddEditError(response.data.Message)
            }
        },
        onRowEditClick: async (e, rowData, isEdit) => {
            let editPlatform = { platformId: rowData.id }
            if (isEdit) setEditDrawer(true)
            setOpenDrawer(true)
            setEditData(editPlatform)
            e.stopPropagation()
        },
        handlePlatformFilter: e => {
            setPlatformFilterValue(e.target.value)
            if (activeLanguage.code === 'nl' && e.target.value.length > 0) {
                const filterd = platforms.filter(plat =>
                    plat.platformName[1].translation.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
                )
                setPlatformsFilterd(filterd)
            } else if (activeLanguage.code === 'fr' && e.target.value.length > 0) {
                const filterd = platforms.filter(plat =>
                    plat.platformName[0].translation.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
                )
                setPlatformsFilterd(filterd)
            } else {
                setPlatformsFilterd(platforms)
            }
        },
        handleAddClick: () => {
            setOpenDrawer(true)
            setEditDrawer(false)
            setEditData('')
        },
        onDrawerClose: () => {
            setOpenDrawer(false)
            setEditDrawer(false)
            setEditData('')
            setAddEditError('')
        },
        onDialogClose: () => {
            setOpenDeleteDialog(false)
            setAddEditError('')
        },
        handleGetPtCars: async () => {
            const response = await SERVICES.getPtCars()
            setPtCarsLists(response)
        },
    }

    const SERVICES = {
        updatePtcarPlatform: async (data, isEdit) => {
            const response = await addEditPtcarPlatform(data, isEdit)
            return response
        },
        deletePtcarPlatform: async id => {
            const response = await deletePlatform(id)
            return response
        },
        getPtcarPlatforms: async () => {
            const response = await getPtcarPlatforms()
            return response
        },
        getPlatformById: async id => {
            const response = await getPlatformById(id)
            return response
        },
        updatePlatformById: async data => {
            const response = await updatePlatform(data)
            return response
        },
        addPlatform: async data => {
            const response = await addPlatform(data)
            return response
        },
        getPtCars: async () => {
            const response = await getPtCars()
            return response
        },
    }

    const initializer = () => {
        METHODS.loadPtcarPlatforms()
        METHODS.handleGetPtCars()
    }

    React.useEffect(() => {
        initializer()
    }, [])

    return <PtcarPlatformsContext.Provider value={{ STATE, METHODS, SERVICES }}>{children}</PtcarPlatformsContext.Provider>
}

StoreProvider.propTypes = {
    children: PropTypes.node,
}

export default StoreProvider
