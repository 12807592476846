import React from 'react'
import { object, bool, func, array, number } from 'prop-types'
import { DateTime } from 'luxon'
import { useForm, Controller } from 'react-hook-form'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import InputLabel from '@material-ui/core/InputLabel'
import { DatePickerWithKeyboard } from 'components/Pickers'
import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'
import SelectInput from 'components/SelectInput'
import LineSelect from '../LineSelect'

import netIds from '../../json/netIdData'

import editLineCall from './editLineCall'
import addNewPtcarCall from './addNewPtCarCall'
import editPtcarCall from './editPtCarCall'
import { PtCarDrawerContext } from './PtCarDrawer.store'

const handleAddClick = async (data, onClose, setError, loadPtcars, translate) => {
    await addNewPtcarCall(data, onClose, setError, loadPtcars, translate)
}

const handleUpdateClick = async (data, onClose, setError, countLine, countNormal, line, id, loadPtcars, translate) => {
    if (countNormal === 0 && countLine === 0) {
        onClose()
    }

    const { MileId, Distance, ...filteredData } = data;

    if (countLine > 0 && countNormal === 0) {
        await editLineCall(MileId, Distance, onClose, setError, line, id, false, loadPtcars, translate)
    }
    if (countNormal > 0 && countLine === 0) {
        await editPtcarCall(filteredData, onClose, setError, id, loadPtcars, translate)
    }
    if (countNormal > 0 && countLine > 0) {
        await editLineCall(MileId, Distance, onClose, setError, line, id, true, loadPtcars, translate)
        await editPtcarCall(filteredData, onClose, setError, id, loadPtcars, translate)
    }
}

const returnName = name => {
    const lower = name.charAt(0).toLowerCase() + name.substr(1)
    return lower.replace(/-/g, '')
}

function textField(
    label,
    value,
    callback,
    setCount,
    count,
    type,
    register,
    errors,
    required = true,
    disabled = true,
    translate = null,
    setUicEdit = null,
    UicEdit = false
) {
    let field = type
    let pattern = {}

    if (type === 'integer') {
        pattern = { pattern: /^([+-]?[1-9]\d*|0)$/ }
    }

    if (type === 'cord') {
        field = 'text'
        pattern = { pattern: /^[+-]?\d+(\.\d+)?$/ }
    }
    if (label === 'uic' && UicEdit) {
        pattern = { pattern: /^(160)([\d]{7})$/ }
    }

    return (
        <React.Fragment>
            <TextField
                name={returnName(label)}
                inputRef={register !== undefined ? register({ required: required, ...pattern }) : register}
                error={errors && !!errors[`${returnName(label)}`]}
                margin="dense"
                id={returnName(label)}
                label={label}
                type={type === 'integer' ? 'number' : field}
                fullWidth
                defaultValue={value}
                required={required}
                onChange={e => {
                    callback(e.target.value)
                    setCount(count + 1)
                    if (label === 'uic' && setUicEdit) {
                        setUicEdit(true)
                    }
                }}
                disabled={!disabled}
            />
            {errors && errors[returnName(label)] && type === 'cord' && translate !== null && (
                <ErrorMessage error={translate('cord_validation')} spacing={false} />
            )}
        </React.Fragment>
    )
}

function datePicker(translate, label, value, callBack, setCount, count, register, errors, name) {
    return (
        <DatePickerWithKeyboard
            format="dd/MM/yyyy"
            name={name}
            id={name}
            error={errors && !!errors[`${name}`]}
            inputRef={register !== undefined ? register({ required: true }) : null}
            label={translate(label)}
            value={value}
            onChange={e => {
                callBack(e)
                setCount(count + 1)
            }}
        />
    )
}

function PtcarDrawer(props) {
    const submitTheForm = (rowId) => { 

        const data = {
            ValidFrom: parseInt(validFrom),
            ValidTo: parseInt(validTo),
            Code: code,
            ShortNameFr: shortNameFr,
            ShortNameNl: shortNameNl,
            LongNameFr: longNameFr,
            LongNameNl: longNameNl,
            CommercialNameFr: commercialNameFr,
            CommercialNameNl: commercialNameNl,
            CommercialMediumNameFr: commercialMediumNameFr,
            CommercialMediumNameNl: commercialMediumNameNl,
            CommercialShortNameFr: commercialShortNameFr,
            CommercialShortNameNl: commercialShortNameNl,
            NetId: netId !== undefined ? netId : '',
            Classification: parseInt(classification),
            Type: parseInt(type),
            PtCarGroup: ptCarGroup,
            Latitude: parseFloat(latitude),
            Longitude: parseFloat(longitude),
            Altitude: parseFloat(altitude),
            InnerBox: parseInt(innerBox),
            OuterBox: parseInt(outerBox),
            UicCode: parseInt(UIC),
            PtCarLookupOther: ptCarLookupOther.length > 1 ? ptCarLookupOther.slice(0, -1) : [],
            MileId: parseInt(mileId) || null,
            Distance: parseFloat(distance) || null
        }

        if (edit === 1) {
            handleUpdateClick(data, onClose, setError, countLine, countNormal, line, rowId, loadPtcars, translate)
        }
        if (edit === 0) {
            handleAddClick(data, onClose, setError, loadPtcars, translate)
        }
    }

    //STORE
    const {
        countNormal,
        setCountNormal,
        ptCarGroup,
        setPtCarGroup,
        error,
        setError,
        countLine,
        setCountLine,
        validFrom,
        setValidFrom,
        validTo,
        setValidTo,
        shortNameFr,
        setShortNameFr,
        shortNameNl,
        setShortNameNl,
        longNameFr,
        setLongNameFr,
        longNameNl,
        setLongNameNl,
        commercialNameFr,
        setCommercialNameFr,
        commercialNameNl,
        setCommercialNameNl,
        commercialShortNameFr,
        setCommercialShortNameFr,
        commercialShortNameNl,
        setCommercialShortNameNl,
        commercialMediumNameFr,
        setCommercialMediumNameFr,
        commercialMediumNameNl,
        setCommercialMediumNameNl,
        classification,
        setClassification,
        code,
        setCode,
        type,
        setType,
        netId,
        setNetId,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        altitude,
        setAltitude,
        innerBox,
        setInnerBox,
        outerBox,
        setOuterBox,
        UIC,
        setUIC,
        UicEdit,
        setUicEdit,
        line,
        lineName,
        milestone,
        mileId,
        setMileId,
        distance,
        setDistance,
        distanceToZero,
        distanceToNextPtCar,
        distanceToPreviousPtCar,
        ptCarLookupOther,
        handlePtCarLookupChange,
        handlePtCarLookupDelete,
    } = React.useContext(PtCarDrawerContext)

    const {
        classes,
        isOpen,
        onClose,
        translate,
        edit,
        rowData,
        openLineNumberDialog,
        loadPtcars,
        handleLineSearchValue,
        lineNumberData,
        selectedLineSearchItem,
        types,
        classifications,
    } = props

    const { id } = rowData

    const onStartTimeChange = e => {
        setValidFrom(e / 1000)
    }
    const onEndTimeChange = e => {
        setValidTo(e / 1000)
    }
    const { register, handleSubmit, errors, control, triggerValidation, setValue } = useForm({
        defaultValues: { netId: netId, classification: classification, type: type },
    })
    const changeDate = date => {
        if (date) return DateTime.fromSeconds(parseInt(date))
        else return null
    }

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <form onSubmit={handleSubmit(() => submitTheForm(id))} noValidate autoComplete="off">
                <Grid
                    container
                    spacing={2}
                    style={{ width: 500, margin: 16, display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography variant="h6">{edit ? 'Edit PtCar' : 'Add PtCar'}</Typography>
                    {edit === 1 && netId !== 88 && (
                        <IconButton icon="add" onClick={openLineNumberDialog} title={translate('add_linenumber')} />
                    )}
                </Grid>
                <div className={classes.drawerRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex' }}>
                                <Paper style={{ padding: '16px' }}>
                                    {edit === 1 && (
                                        <div style={{ paddingBottom: '16px' }}>
                                            <Typography variant="body1">PtcarId: {id}</Typography>
                                            <Typography>LineNumber: {lineName.length > 0 ? lineName : 'N/A'}</Typography>

                                            {rowData.ptCarKmMarks.length > 1 && (
                                                <Typography>
                                                    {`Crossing: (${rowData.ptCarKmMarks
                                                        .filter(item => item.lineNumber !== line)
                                                        .map(e => e.lineName)
                                                        .join(', ')})`}
                                                </Typography>
                                            )}

                                            <Typography variant="body1">Milestone: {milestone}</Typography>
                                            <Typography variant="body1">DistanceToZero: {distanceToZero}</Typography>

                                            <Typography variant="body1">
                                                DistanceToNextPtCar: {distanceToNextPtCar}
                                            </Typography>
                                            <Typography variant="body1">
                                                DistanceToPreviousPtCar: {distanceToPreviousPtCar}
                                            </Typography>

                                        </div>
                                    )}

                                    {line.length > 0 && edit && netId != 88 ? (
                                        <div style={{ marginBottom: '24px' }}>
                                            <div className={classes.twoElementDiv}>
                                                <Grid item xs={5}>
                                                    {textField(
                                                        'MileId',
                                                        mileId,
                                                        setMileId,
                                                        setCountLine,
                                                        countLine,
                                                        'integer',
                                                        register,
                                                        errors
                                                    )}
                                                </Grid>

                                                <Grid item xs={5}>
                                                    {textField(
                                                        'Distance',
                                                        distance,
                                                        setDistance,
                                                        setCountLine,
                                                        countLine,
                                                        'number',
                                                        register,
                                                        errors
                                                    )}
                                                </Grid>
                                            </div>
                                        </div>
                                    ): null}
                                    {!edit && (
                                        <div style={{ marginBottom: '24px' }}>
                                            <LineSelect
                                                handleSearchValue={handleLineSearchValue}
                                                data={lineNumberData}
                                                lineSelectValue={selectedLineSearchItem}
                                                classes={classes}
                                                register={register !== undefined ? register : null}
                                                errors={errors}
                                                triggerValidation={triggerValidation}
                                            />
                                            <div className={classes.twoElementDiv}>
                                                <Grid item xs={5}>
                                                    {textField(
                                                        'MileId',
                                                        mileId,
                                                        setMileId,
                                                        setCountLine,
                                                        countLine,
                                                        'integer',
                                                        register,
                                                        errors,
                                                        selectedLineSearchItem.code.length > 1
                                                    )}
                                                </Grid>

                                                <Grid item xs={5}>
                                                    {textField(
                                                        'Distance',
                                                        distance,
                                                        setDistance,
                                                        setCountLine,
                                                        countLine,
                                                        'number',
                                                        register,
                                                        errors,
                                                        selectedLineSearchItem.code.length > 1
                                                    )}
                                                </Grid>
                                            </div>
                                        </div>
                                    )}

                                    {edit === 1 && netId == 88 ? null :(
                                        <>
                                        <div className={classes.twoElementDiv}>
                                            <Grid item xs={5}>
                                                {datePicker(
                                                    translate,
                                                    'datatable_header_valid_from',
                                                    changeDate(validFrom),
                                                    onStartTimeChange,
                                                    setCountNormal,
                                                    countNormal,
                                                    register,
                                                    errors,
                                                    'validFrom'
                                                )}
                                            </Grid>
                                            <Grid item xs={5}>
                                                {datePicker(
                                                    translate,
                                                    'datatable_header_valid_to',
                                                    changeDate(validTo),
                                                    onEndTimeChange,
                                                    setCountNormal,
                                                    countNormal,
                                                    register,
                                                    errors,
                                                    'validTo'
                                                )}
                                            </Grid>
                                        </div>

                                        <div className={classes.twoElementDiv}>
                                            <Grid item xs={5}>
                                                {textField(
                                                    'Short-NameFr',
                                                    shortNameFr,
                                                    setShortNameFr,
                                                    setCountNormal,
                                                    countNormal,
                                                    'text',
                                                    register,
                                                    errors
                                                )}
                                            </Grid>

                                            <Grid item xs={5}>
                                                {textField(
                                                    'Short-NameNl',
                                                    shortNameNl,
                                                    setShortNameNl,
                                                    setCountNormal,
                                                    countNormal,
                                                    'text',
                                                    register,
                                                    errors
                                                )}
                                            </Grid>
                                        </div>

                                        {textField(
                                            'Long-NameFr',
                                            longNameFr,
                                            setLongNameFr,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Long-NameNl',
                                            longNameNl,
                                            setLongNameNl,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-NameFr',
                                            commercialNameFr,
                                            setCommercialNameFr,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-NameNl',
                                            commercialNameNl,
                                            setCommercialNameNl,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-Short-NameFr',
                                            commercialShortNameFr,
                                            setCommercialShortNameFr,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-Short-NameNl',
                                            commercialShortNameNl,
                                            setCommercialShortNameNl,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-Medium-NameFr',
                                            commercialMediumNameFr,
                                            setCommercialMediumNameFr,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}
                                        {textField(
                                            'Commercial-Medium-NameNl',
                                            commercialMediumNameNl,
                                            setCommercialMediumNameNl,
                                            setCountNormal,
                                            countNormal,
                                            'text',
                                            register,
                                            errors
                                        )}

                                        <div className={classes.twoElementDiv}>
                                            <Grid item xs={5}>
                                                <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                                                    <InputLabel htmlFor={'classification'}>{'Classification'}</InputLabel>
                                                    <Controller
                                                        as={
                                                            <SelectInput
                                                                minWidth={200}
                                                                error={errors && !!errors[`classification`]}
                                                                id="classification"
                                                                classes={classes}
                                                                name="classification"
                                                                items={classifications}
                                                            />
                                                        }
                                                        onChange={([event]) => {
                                                            setClassification(event.target.value)
                                                            setCountNormal(countNormal + 1)
                                                            setTimeout(() =>
                                                                setValue('classification', event.target.value, true)
                                                            )
                                                        }}
                                                        defaultValue={classification}
                                                        rules={{ required: true, minLength: 1 }}
                                                        register={register}
                                                        name="classification"
                                                        control={control}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={5}>
                                                {textField(
                                                    'Code',
                                                    code,
                                                    setCode,
                                                    setCountNormal,
                                                    countNormal,
                                                    'text',
                                                    register,
                                                    errors
                                                )}
                                            </Grid>
                                        </div>
                                        </>
                                        )
                                    }       

                                    <div>
                                        <Grid item xs={6}>
                                            {textField(
                                                'PtCarGroup',
                                                ptCarGroup,
                                                setPtCarGroup,
                                                setCountNormal,
                                                countNormal,
                                                'text',
                                                register,
                                                errors,
                                                false
                                            )}
                                        </Grid>
                                    </div>
                                    {edit === 1 && netId == 88 ? null : (
                                        <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                                            <InputLabel htmlFor={'type'}>{'Type'}</InputLabel>
                                            <Controller
                                                as={
                                                    <SelectInput
                                                        minWidth={200}
                                                        error={errors && !!errors[`type`]}
                                                        id="type"
                                                        classes={classes}
                                                        name="type"
                                                        items={types}
                                                    />
                                                }
                                                onChange={([event]) => {
                                                    setType(event.target.value)
                                                    setCountNormal(countNormal + 1)
                                                    setTimeout(() => setValue('type', event.target.value, true))
                                                }}
                                                defaultValue={type}
                                                rules={{ required: true, minLength: 1 }}
                                                register={register}
                                                name="type"
                                                control={control}
                                            />
                                        </div>
                                    )}
                                    {edit === 1 && netId == 88 ? null : (
                                        <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                                            <InputLabel htmlFor={'netId'}>{'NetId'}</InputLabel>
                                            <Controller
                                                as={
                                                    <SelectInput
                                                    minWidth={200}
                                                    error={errors && !!errors[`netId`]}
                                                    id="netId"
                                                    classes={classes}
                                                    name="netId"
                                                    items={netIds.map(item => {
                                                        return {
                                                            id: item.netId,
                                                            text: item.label === ' ' ? 'Net Id' : item.label,
                                                        }
                                                    })}
                                                    />
                                                }
                                                onChange={([event]) => {
                                                    setNetId(event.target.value)
                                                    setCountNormal(countNormal + 1)
                                                    setTimeout(() => setValue('netId', event.target.value, true))
                                                }}
                                                defaultValue={netId}
                                                // eslint-disable-next-line max-len
                                                rules={{ required: true, minLength: { value: 2, message: 'Minimum length is 2' }, validate: value => edit !== 0 || value !== 88 || translate('only_foreign_ptcars_allowed') }}
                                                register={register}
                                                name="netId"
                                                control={control}
                                                />
                                            {errors.netId && <ErrorMessage error={errors.netId.message} spacing={false}/>}
                                        </div>
                                        )
                                    }
                                    <div className={classes.twoElementDiv}>
                                        <Grid item xs={3}>
                                            {textField(
                                                'latitude',
                                                latitude,
                                                setLatitude,
                                                setCountNormal,
                                                countNormal,
                                                'cord',
                                                register,
                                                errors,
                                                true,
                                                translate
                                            )}
                                        </Grid>

                                        <Grid item xs={3}>
                                            {textField(
                                                'longitude',
                                                longitude,
                                                setLongitude,
                                                setCountNormal,
                                                countNormal,
                                                'cord',
                                                register,
                                                errors,
                                                true,
                                                translate
                                            )}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {textField(
                                                translate('datatable_header_ptcar_altitude'),
                                                altitude,
                                                setAltitude,
                                                setCountNormal,
                                                countNormal,
                                                'cord',
                                                register,
                                                errors,
                                                false,
                                                translate
                                            )}
                                        </Grid>
                                    </div>
                                    <div className={classes.twoElementDiv}>
                                        <Grid item xs={5}>
                                            {textField(
                                                translate('ptcar_innerBox'),
                                                innerBox,
                                                setInnerBox,
                                                setCountNormal,
                                                countNormal,
                                                'integer',
                                                register,
                                                errors,
                                                true,
                                                translate
                                            )}
                                        </Grid>

                                        <Grid item xs={5}>
                                            {textField(
                                                translate('ptcar_outerBox'),
                                                outerBox,
                                                setOuterBox,
                                                setCountNormal,
                                                countNormal,
                                                'integer',
                                                register,
                                                errors,
                                                true,
                                                translate
                                            )}
                                        </Grid>
                                    </div>
                                    {edit === 1 && netId == 88 ? null : (
                                        <Grid item xs={5}>
                                            {textField(
                                                'Uic',
                                                UIC,
                                                setUIC,
                                                setCountNormal,
                                                countNormal,
                                                'integer',
                                                register,
                                                errors,
                                                true,
                                                translate,
                                                setUicEdit,
                                                UicEdit
                                            )}
                                        </Grid>
                                    )}

                                    <div className={classes.twoElementDiv} style={{alignItems: 'start', marginTop: '8px'}}>
                                        {rowData.ptCarLookupField != null &&  rowData.ptCarLookupField.ptCar.length > 0 ? (
                                            <>
                                                <Grid item xs={5.5}>
                                                    {rowData.ptCarLookupField.ptCar.map((element, i) => {
                                                        return(
                                                            <div className={classes.ptCarLookupContainer} key={i}>
                                                                        <TextField
                                                                            className={classes.lookupTextfield}
                                                                            label={i == 0 ? 'lookUpField': null}
                                                                            fullWidth={true}
                                                                            value={element}
                                                                            disabled
                                                                            />
                                                                    </div>
                                                                )
                                                            })
                                                        }    
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {ptCarLookupOther.map((element, i) => {
                                                        return(
                                                            <div className={classes.ptCarLookupContainer} key={i}>
                                                                        <TextField
                                                                            className={classes.lookupTextfield}
                                                                            label={i == 0 ? ' ': null}
                                                                            fullWidth={true}
                                                                            value={element}
                                                                            onChange={e => (setCountNormal(countNormal + 1),
                                                                                handlePtCarLookupChange(e.target.value, i))}
                                                                                />
                                                                        {
                                                                            element != '' &&
                                                                            <Delete
                                                                            className={classes.deleteIcon}
                                                                            onClick={() => (setCountNormal(countNormal + 1),
                                                                                handlePtCarLookupDelete(i))}
                                                                                />
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        } 
                                                </Grid>
                                            </>
                                        ) : 
                                        <Grid item xs={5}>
                                                    {ptCarLookupOther.map((element, i) => {
                                                        return(
                                                            <div className={classes.ptCarLookupContainer} key={i}>
                                                                        <TextField
                                                                            className={classes.lookupTextfield}
                                                                            label={i == 0 ? 'lookUpField': null}
                                                                            fullWidth={true}
                                                                            value={element}
                                                                            onChange={e => (setCountNormal(countNormal + 1), 
                                                                                handlePtCarLookupChange(e.target.value, i))}
                                                                                />
                                                                        {
                                                                            element != '' &&
                                                                            <Delete
                                                                            className={classes.deleteIcon}
                                                                            onClick={() => (setCountNormal(countNormal + 1),
                                                                                handlePtCarLookupDelete(i))}
                                                                                />
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        } 
                                                </Grid>
                                        }
                                    </div>
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.saveButton}>
                    {error.length > 0 && <ErrorMessage error={error} spacing={false} />}
                    {edit === 1 && <IconButton type="submit" icon="save" title={translate('add_user_confirm_save')} />}
                    {edit === 0 && <IconButton icon="save" type="submit" title={translate('button_add')} />}
                </div>
            </form>
        </Drawer>
    )
}

PtcarDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    rowData: object,
    translate: func,
    edit: number,
    openLineNumberDialog: func,
    loadPtcars: func,
    handleLineSearchValue: func,
    lineNumberData: array,
    selectedLineSearchItem: object,
    types: array,
    classifications: array,
    ptCarLookupOther: array,
    handlePtCarLookupDelete: func,
    handlePtCarLookupChange: func,
    setIsChanged: func,
}

export default PtcarDrawer
