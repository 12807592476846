import axios from 'axios';

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}


// eslint-disable-next-line no-unused-vars
async function editPtcarCall(data, onClose, setError, id, loadPtcars, translate) {
    const { url, subscriptionKey } = requestData
    
    const fullUrl = `${url}/legacyReferenceData/ptcar/${id}/update`
    try {
        const response = await axios.post(fullUrl, data, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            onClose()
        }
        if (response.status === 200) {
            onClose()
            loadPtcars()
        }
    } catch (e) {
        if (e.response.status === 400) {
            setError(e.response.data.Message)
        } else {
            setError(translate('edit_ptcar_error'))
        }
        console.error(e) // eslint-disable-line
    }
}

export default editPtcarCall;