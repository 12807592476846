import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

async function editLineCall(mileId, distance, onClose, setError, line, id, both, loadPtcars, translate) {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/legacyReferenceData/ptcar/${id}/line/${line}/update`
    try {
        const response = await axios.post(fullUrl, {
            MileId: mileId,
            Distance: distance,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            onClose()
        }
        if (response.status === 200) {
            onClose()
            if (both === false) {
                loadPtcars()
            }
        }
    } catch (e) {
        if (e.response.status === 400) {
            setError(e.response.data.Message)
        } else {
            setError(translate('edit_line_error'))
        }
        console.error(e) // eslint-disable-line
    }
}

export default editLineCall
